import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HubspotForm from 'react-hubspot-form'
// import JotformEmbed from 'react-jotform-embed'

const SectionQualifyNewForm = () => {
  const data = useStaticQuery(graphql`
    query SectionQualifyNewFormQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px", height: "mvh", zIndex: "-10" }}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          objectFit: "contain",
          opacity: "0.7",
        }}
        alt="hero image"
        image={imgDefault}
        formats={["auto", "webp", "avif"]}
        className="fixed h-screen"
      />

      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 mb-14 xl:mb-24 mt-24">
          <div className="flex flex-col justify-center">
            <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-6 xl:mt-4">
              Qualify for an 831(b) Plan
            </h2>

            <p className="text-white text-lg md:text-xl text-center mt-6">Please fill out and submit this form to qualify for an 831(b) Plan.</p>

            {/** 
            <div className="bg-white w-11/12 max-w-lg mt-12 mx-auto pb-8 shadow-lg">
              <JotformEmbed src="https://form.jotform.com/233397201423147" />
            </div>
            */}

            <div className="bg-white w-11/12 max-w-lg mt-12 mx-auto p-8 shadow-lg">
              <HubspotForm
                region="na1"
                portalId='23182726'
                formId='8232deb7-ba17-4796-b2ac-79d2785dd83a'
                loading={<div className='italic'>Loading Qualify form...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionQualifyNewForm