import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"

import LayoutNew from "../../components/layout-new"
import Seo from "../../components/seo"
import SectionQualifyNewForm from "../../components/sections/section-qualify-new-form"

const PageNewQualifyForm = () => {
  return (
    <LayoutNew>
      <Seo
        title="Qualify for an 831(b) Plan"
        description="Please fill out and submit this form to qualify for an 831(b) Plan."
        noIndex
      />

      <SectionQualifyNewForm />
    </LayoutNew>
  )
}

export default PageNewQualifyForm
